import React from "react";
import { Link } from "gatsby";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Layout from "~components/base/layout";
import SEO from "~components/base/seo";
import ProductsSlider from "~components/products/productsSlider";
import "../styles/pages/_lp.scss";
import converse from "../images/lp/TSD_Converse_Hero.png";
import sound1Image from "../images/lp/TSD_Sound_1.png";
import sound2Image from "../images/lp/TSD_Sound_2.png";
import sound3Image from "../images/lp/TSD_Sound_3.png";
import sound1 from "../audio/Audio1.mp3";
import sound2 from "../audio/Audio2.mp3";
import sound3 from "../audio/Audio3.mp3";

var settings = {
  centerMode: true,
  infinite: true,
  centerPadding: "700px",
  slidesToShow: 1,
  dots: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 2500,
      settings: {
        centerPadding: "500px",
      },
    },
    {
      breakpoint: 2000,
      settings: {
        centerPadding: "300px",
      },
    },
    {
      breakpoint: 1400,
      settings: {
        centerPadding: "100px",
      },
    },
    {
      breakpoint: 821,
      settings: {
        centerPadding: "40px",
      },
    },
    {
      breakpoint: 600,
      settings: {
        centerPadding: "0px",
      },
    },
  ],
};
const MarketingStuck = () => (
  <Layout type="marketin-stuck-lp template-lp">
    <SEO
      title="Marketing Got You Stuck? Meet The Simple Department."
      description="Stuck with marketing problems? A 1-hour consult with a Fractional CMO can help – at a fraction of the price. Book your consultation today."
    />

    <section className="ms-lp-hero">
      <img src={converse} />
      <div className="container">
        <div className="content-wrapper">
          <h2>What has you feeling</h2>
          <h1>Stuck?</h1>
          <p>
            Marketing is incredibly fun, but it can be overwhelming and there
            are thousands of little traps you can set for yourself that make you
            feel overwhelmed, lost and can cost you time and money that you will
            never get back. Every big and well funded company/brand ahs the
            luxury of a full-time CMO and usually a stacked marketing department
            to go along with them. For those of us that don't have an extra
            $500,000-$1,000,000 a year lying around to spend on salaries - we
            created the Fractional CMO.
          </p>
          <p>
            <strong>
              Bite-sized pricing, for the full-meal deal in Marketing Support.
            </strong>
          </p>
          <p className="btn-cta-wrapper">
            <strong>
              <Link to="#TSD-CMO-Packages" className="lp-btn red-btn">
                Need to un-stuck yourself?
              </Link>
            </strong>
          </p>
        </div>
        <h3> Are you suffering from any of the following symptoms?</h3>
      </div>
    </section>
    <section className="ms-lp-slider">
      <Slider {...settings} className="symptoms-slider">
        <div className="give-padding">
          <div className="single-slide slide1">
            <div className="slide-content">
              <h3>All the ads, but none of the sales?</h3>
              <p>
                Some Meta or Google guru told you they knew everything about
                everything and gauranteed you some insane growth number - but
                all you are left with is 0-3 sales and whole bunch of garbage
                data?
              </p>
            </div>
          </div>
        </div>
        <div className="give-padding">
          <div className="single-slide slide2">
            <div className="slide-content">
              <h3>No One is Talking About You?</h3>
              <p>
                From the early days of society, there has been one common truth
                - if people are not talking about you, do you even exist?
              </p>
            </div>
          </div>
        </div>
        <div className="give-padding">
          <div className="single-slide slide3">
            <div className="slide-content">
              <h3>Too many opinions, not enough action?</h3>
              <p>
                When you ask for the advice and opinions of people who have no
                skin in the game, you are almost always going to get ST-UCKED.
              </p>
            </div>
          </div>
        </div>
      </Slider>
      <div className="container">
        <h3 className="text-center">
          Whatever your challenge, there is a good chance that what you are
          missing is the perspective a CMO would bring to your team
        </h3>
        <p className="text-center spacer-1">
          <strong>(and yes, we know, you probably are the entire team).</strong>
        </p>
        <p className="btn-cta-wrapper text-center">
          <strong>
            <Link to="#TSD-CMO-Packages" className="lp-btn white-btn">
              Meet Your New CMO
            </Link>
          </strong>
        </p>
      </div>
    </section>
    <section className="ms-lp-action">
      <div className="container">
        <div>
          <h2>
            Us. <br></br>In action.
          </h2>
          <p>
            Curious about what we know, how we think and what our sessions will
            be like? Below are some of our favourite samples for you to try
            before you buy.
          </p>
        </div>
        <div className="audio-container">
          <div className="single-audio">
            <img src={sound1Image} alt="" />
            <figure>
              <div>
                <figcaption>
                  <strong>Developing Unique Audience Segments</strong>
                </figcaption>
                <p>
                  Algorithms Do The Hard Work to Find New Members for Food
                  Subscription Service
                </p>
              </div>
              <audio controls src={sound1}>
                Your browser does not support the
                <code>audio</code> element.
              </audio>
            </figure>
          </div>
          <div className="single-audio">
            <img src={sound2Image} alt="" />
            <figure>
              <div>
                <figcaption>
                  <strong>Targeting Niche Problems</strong>
                </figcaption>
                <p>The Power of PPC in the Skincare Space</p>
              </div>
              <audio controls src={sound2}>
                Your browser does not support the
                <code>audio</code> element.
              </audio>
            </figure>
          </div>
          <div className="single-audio">
            <img src={sound3Image} alt="" />
            <figure>
              <div>
                <figcaption>
                  <strong>Finding Community</strong>
                </figcaption>
                <p>Social Media Advertising to Connect Farmers to Families</p>
              </div>
              <audio controls src={sound3}>
                Your browser does not support the
                <code>audio</code> element.
              </audio>
            </figure>
          </div>
        </div>
      </div>
    </section>
    <section className="ms-lp-cake">
      <div className="container">
        <div className="content-wrapper">
          <h2>
            Have your cake.<br></br>And eat it too.
          </h2>
          <p>
            <strong>
              And get a detailed report on how good it tasted and the ROCE
              (Return On Cake Eating)
            </strong>
          </p>
          <p>
            A <strong>fractional-CMO</strong>, allows you to add a{" "}
            <strong>$300,000</strong> marketing strategist, visionary, brand
            manager and creative director type leader – for a fraction of the
            cost. A CMO is hyper-focused on granular problem solving as you need
            it and when the advice can have the most amount of impact.
          </p>
          <p className="btn-cta-wrapper">
            <strong>
              <Link to="#TSD-CMO-Packages" className="lp-btn blue-btn">
                Get Your Cake
              </Link>
            </strong>
          </p>
        </div>
      </div>
    </section>

    <section className="ms-lp-products" id="TSD-CMO-Packages">
      <div className="container">
        <div className="content-wrapper">
          <h2 className="text-center">Book your CMO consultation</h2>
        </div>
        <ProductsSlider>
          <div
            title="Dissecting Your Data"
            duration="(1 HOUR)"
            id="cmo-dissecting"
            price="$250"
          >
            <p>
              In this 1-hour session, we will break down your data and help you
              use it to take informed action.
            </p>
          </div>
          <div
            title="Avoid Stupid Mistakes"
            duration="(1 HOUR)"
            id="cmo-mistakes"
            price="$250"
          >
            <p>
              Perspective, objectivity and experience. That is what we bring to
              the conversation. Choose this option if you’re launching a new
              product, brand, service or market.
            </p>
          </div>
          <div
            title="An Honest Assessment"
            duration="(2 HOURS)"
            id="cmo-honest"
            price="$500"
          >
            <p>
              Need someone to be real with you? Here we poke, prod, and examine
              everything to tell you what’s working, what isn’t, what (or who)
              to drop, and give you our recommendations on growth and actionable
              ideas.
            </p>
          </div>
          <div
            title="Keeping It Simple, since ’83 (HOURLY)"
            id="cmo-hr"
            price="$250/hr"
          >
            <p>
              At The Simple Department, we are used to helping companies like
              yours assess, troubleshoot, and move forward. These are open
              sessions that can help completely change your trajectory – think
              of it as therapy sessions for your business.
            </p>
          </div>
          <div
            title="CMO LAUNCH PACKAGE"
            duration="(3 MONTHS)"
            id="cmo-3"
            price="$18,000"
          >
            <p>
              Best to lock in dedicated time to help get smaller projects done
              from start to finish.
            </p>
          </div>
          <div
            title="CMO GROWTH PACKAGE"
            duration="(6 MONTHS)"
            id="cmo-6"
            price="$30,000"
          >
            <p>
              A great way to get a half-years worth of full support on multiple
              projects, campaigns and longer term planning.
            </p>
          </div>
          <div
            title="CMO BUILD PACKAGE"
            duration="(12 MONTHS)"
            id="cmo-12"
            price="$55,000"
          >
            <p>
              Ready to turn your small idea or business into something huge? Our
              CMO Build Package is all about turning your small business into a
              big deal.
            </p>
          </div>
        </ProductsSlider>
      </div>
    </section>
  </Layout>
);

export default MarketingStuck;
